
import { Vue, Component } from "vue-property-decorator";
import { getTermsAndConditionsS3Path } from "@/utility/StaticFileHelper";

@Component({
  metaInfo() {
    return {
      title: "Vanliga frågor om vår tjänst - Dealfinder",
      meta: [
        {
          name: "qna",
          content: "De vanligaste frågorna om hur vår verksamhet fungerar."
        }
      ]
    };
  }
})
export default class QuestionsAndAnswers extends Vue {
  get getFilePath(): string {
    return getTermsAndConditionsS3Path();
  }
}
